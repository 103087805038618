<template>
    <carousel class="carousel" :perPage="1" :navigationEnabled="true" paginationPosition="bottom-overlay" :paginationColor="paginationColor">
      <slide class="slide" v-for="(slide, index) in slides" :key="index">
        <img width="400" :src="slide.image"/>
        <div class="slide-info">
          <h1>{{ translate(slide.title)}}</h1>
          <p>{{ translate(slide.description)}}</p>
          <b-button variant="secondary" class="carousel-button" @click="toLink()">
            {{ translate(slide.button_text) }}
          </b-button>
        </div>
      </slide>
    </carousel>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util"

export default {
  props: ["carousel"],
  data() {
    return {
      slides: this.carousel.slides,
    };
  },
  computed: {
    backgroundImage() {
      console.log("banner " + this.carousel)
      return this.carousel.image;
    },
    locale() {
      return localStorage.getItem("locale")
    },
    styling() {
      return this.$store.state.Styling.styling
    },
    paginationColor() {
      return this.styling.secondary
    }
  },
  methods: {
    toLink() {
      this.$router.push("/home");
    },
    translate(value) {
      return translateSetting(value, this.locale)
    }
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.carousel { 
  margin-bottom: 30px;
  .slide {
    display: flex;
    background-color: #f7fafc;
    .slide-info {
      height: 100%;
      padding: 15px;
      .carousel-button {
        position: absolute;
        bottom: 45px;
      }
    }
  }
}

</style>
