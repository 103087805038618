<template>
    <b-jumbotron
      class="banner-jumbo"
      :style="
        `background-image: linear-gradient(rgba(0,0,0,0.9), rgba(50,50,50,0.4)), url(${backgroundImage})`
      "
      bg-variant="secondary"
      text-variant="white"
    >
      <template #header>{{ translate(banner.title) }}</template>
      <p>{{ translate(banner.description) }}</p>
      <hr class="my-4" />
      <b-button variant="secondary" @click="toLink()">
        {{ translate(banner.button_text) }}
      </b-button>
    </b-jumbotron>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util"

export default {
  props: ["banner"],
  computed: {
    backgroundImage() {
      return this.banner.image;
    },
    locale() {
      // const language = localStorage.getItem("locale")
      // return this.$i18n.t(`languages.${language}`).toLowerCase()
      return localStorage.getItem("locale")
    },
  },
  methods: {
    toLink() {
      this.$router.push("/home");
    },
    translate(value) {
      return translateSetting(value, this.locale)
    }
  },
};
</script>
<style>
.banner-jumbo {
  background-size: cover;
}
</style>
