<template>
  <div class="card">
    <div class="card-header">
      <!-- Title -->
      <h4 class="card-header-title">Online Users</h4>

      <!-- Link -->
        <toggle-button class="online-toggle" :width="30" :height="15" :color="onlineColor" v-model="checked" name="check-button"/>
        <p class="toggle-text">{{ checked ? "Go offline" : "Go online" }}</p>
    </div>
    <div class="card-body">
      <!-- List group -->
      <div class="list-group list-group-flush my-n3">
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="profile-posts.html" class="avatar">
                <img
                  src="@/assets/avatar-4.jpg"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1">
                <a href="profile-posts.html">Dianna Smiley</a>
              </h4>

              <!-- Status -->
              <p class="card-text small">
                <span class="text-success">●</span> Online
              </p>
            </div>
          </div>
        </div>
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="profile-posts.html" class="avatar">
                <img
                  src="@/assets/avatar.png"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1">
                <a href="profile-posts.html">Ab Hadley</a>
              </h4>

              <!-- Status -->
              <p class="card-text small">
                <span class="text-success">●</span> Online
              </p>
            </div>
          </div>
        </div>
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="profile-posts.html" class="avatar">
                <img
                  src="@/assets/avatar.png"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1">
                <a href="profile-posts.html">Adolfo Hess</a>
              </h4>

              <!-- Status -->
              <p class="card-text small">
                <span class="text-success">●</span> Online
              </p>
            </div>
          </div>
        </div>
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col-auto">
              <!-- Avatar -->
              <a href="profile-posts.html" class="avatar">
                <img
                  src="@/assets/avatar-1.jpg"
                  alt="..."
                  class="avatar-img rounded-circle"
                />
              </a>
            </div>
            <div class="col ms-n2">
              <!-- Title -->
              <h4 class="mb-1">
                <a href="profile-posts.html">Daniela Dewitt</a>
              </h4>

              <!-- Status -->
              <p class="card-text small">
                <span class="text-success">●</span> Online
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
    }
  },
  computed: {
    onlineColor() {
      return this.styling.success
    },
    styling() {
      return this.$store.state.Styling
    },
  }
}
  
</script>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.card {
  border: none;
  margin-top: 20px;
  color: $color-primary;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  word-wrap: break-word;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  margin-bottom: 1.5rem;

  a {
    color: $color-primary;
  }
}

.card-header {
  color: white;
  background-color: $color-primary;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 1rem 1.5rem;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;

  a {
    color: white;
  }
}

.card-header-title {
  font-weight: 500;
  line-height: 1.1;
  font-size: 0.9375rem;
  letter-spacing: -0.02em;
  flex: 1;
}

.online-toggle {
  margin-top: 10px;
}

.toggle-text {
  font-weight: 500;
  line-height: 1.1;
  font-size: 0.9375rem;
  letter-spacing: -0.02em;
  margin-left: 5px;
  margin-top: 15px;
}


.card-body {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  word-wrap: break-word;
  flex: 1 1 auto;
  padding: 1.5rem;
  flex-shrink: 0;

  a {
    font-size: 15px;
  }
}


.list-group-items {
    font-family: var(--bs-font-sans-serif);
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    word-wrap: break-word;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #edf2f9;
    color: #283e59;
    display: block;
    padding: 1rem 1.25rem;
    position: relative;
    border-width: 0 0 1px;
    padding-left: 0;
    padding-right: 0;
}


.avatar {
  img{
     width: 40px;
     height: 40px;
    font-family: var(--bs-font-sans-serif);
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-sizing: border-box;
    color: #2c7be5;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    position: relative;
  }
    
}


.avatar-rounded-circle {
    font-family: var(--bs-font-sans-serif);
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    color: #2c7be5;
    font-size: 1rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 50%!important;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.col-auto {
    font-family: var(--bs-font-sans-serif);
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    color: #283e59;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-sizing: border-box;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)/2);
    padding-right: calc(var(--bs-gutter-x)/2);
    flex: 0 0 auto;
    width: auto;
}
.avatar:after {
    content: "";
    height: 0;
    position: absolute;
    width: 0;
}
div.custom-checkbox.success label::before,
div[role="group"].success div.custom-checkbox label::before {
    color: #fff!important;
  background-color: #28a745!important;
  border-color: #28a745!important;
}
</style>