<template>
  <b-container class="dashboard" fluid>
    <b-row>
      <b-col v-if="content.intro.active" cols="12" md="3" xl="2">
        <Intro/>
      </b-col>
      <b-col :cols="enlargeMiddleCol ? 12 : 9" :md="enlargeMiddleCol ? 6 : 8" :xl="enlargeMiddleCol ? 7 : 9">
        <Items/>
      </b-col>
      <b-col v-if="interactionActive" cols="12" md="3" xl="3" class="d-lg-block d-xs-none">
        <Chat
          v-if="content.interaction.type == 'vimeo' || content.interaction.type == 'slido'"
          :type="content.interaction.type"
          :code="content.interaction.id"
        />
        <OnlineUsers
          v-if="content.interaction.type == 'online-users'"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Intro from "@/components/landing/Intro.vue"
import Items from "@/components/landing/Items.vue";
import OnlineUsers from "@/components/landing/OnlineUsers.vue"
import Chat from "@/components/chat/Chat.vue";

export default {
  name: "Dashboard",
  components: {
    Intro,
    Items,
    Chat,
    OnlineUsers
  },
  async created() {
    await this.$store.dispatch("Dash/getDashboardContent");
  },
  computed: {
    content() {
      return this.$store.state.Dash.content;
    },
    interactionActive() {
      return this.content.interaction.active
    },
    introActive() { 
      return this.content.intro.active
    },
    enlargeMiddleCol() {
      return (this.interactionActive && this.introActive)
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  flex-grow: 1;
  padding-bottom: 50px;
}
</style>
