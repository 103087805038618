<template>
  <b-row>
    <b-col 
      v-for="(block, index) in blocks"
      :key="index"
      cols="12"
      >
      <Banner v-if="block.type.toLowerCase() == 'banner' && block.active" :banner="block"/>
      <CardList v-if="block.type.toLowerCase() == 'items' && block.active" :cardlist="block"/>
      <Carousel v-if="block.type.toLowerCase() == 'carousel' && block.active" :carousel="block"/>
    </b-col>
  </b-row>
</template>
<script>
import Banner from "@/components/landing/Banner.vue"
import CardList from "@/components/landing/CardList.vue"
import Carousel from "@/components/landing/Carousel.vue"

export default {
  name: "Items",
  components: {
    Banner,
    CardList,
    Carousel
  },
  computed: {
    blocks() {
      return this.$store.state.Dash.content.blocks
    }
  },
};
</script>